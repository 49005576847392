import { CoreComponent } from "../core/core";
import movieStore, { searchMovies } from '../store/movie.js';

export default class MovieListMore extends CoreComponent {
  constructor() {
    super({
      tagName: 'button'
    })
    movieStore.subscribe('pageMax', () => {
      // movieStore.state.page
      // movieStore.state.pageMax
      const { page, pageMax } = movieStore.state;
      pageMax > page
        ? this.el.classList.remove('hide') 
        : this.el.classList.add('hide');
      // if (pageMax > page) {
      //   this.el.classList.remove('hide')
      // } else {
      //   this.el.classList.add('hide')
      // }
    })
  }
  render() {
    this.el.classList.add('btn', 'btn-primary', 'movie-list-more', 'hide');
    this.el.textContent = 'More';

    this.el.addEventListener('click', async event => {
      this.el.classList.add('hide');
      await searchMovies(movieStore.state.page + 1);
    })
  }
}