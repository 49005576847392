import { CoreComponent } from "../core/core";
import movieStore from '../store/movie.js';
import MovieItem from './MovieItem.js';

export default class MovieList extends CoreComponent {
  constructor() {
    super()
    // 변화가 생기면 콜백 실행
    movieStore.subscribe('movies', () => {
      this.render();
    })
    movieStore.subscribe('loading', () => {
      this.render();
    })
    movieStore.subscribe('message', () => {
      this.render();
    })
  }
  render() {
    this.el.classList.add('movie-list');
    this.el.innerHTML = /* html */ `
      ${movieStore.state.message 
        ? `<div class="message">${movieStore.state.message}</div>`
        : '<div class="movies"></div>'
      }
      <div class="loading hide"></div>
    `

    const moviesEl = this.el.querySelector('.movies');
    // movieEl이 존재할 때만 실행
    moviesEl?.append(
      ...movieStore.state.movies.map(movie => {
        // return movie.Title
        return new MovieItem({
          // movie: movie // movieItem에 movie를 넘겨줌, 콜론 생략 가능
          movie
        }).el
      }) // 새로운 배열 반환
    )
    // 전개연산자 없이 사용
    // movieStore.state.movies.map(movie => {
    //   let movieItem = new MovieItem({
    //     movie
    //   });
    //   moviesEl.append(movieItem.el);
    // });

    // 로딩
    const loadingEl = this.el.querySelector('.loading');
    movieStore.state.loading
      ? loadingEl.classList.remove('hide')
      : loadingEl.classList.add('hide');
  }
}