import { CoreComponent } from "../core/core";
import movieStore, { searchMovies } from '../store/movie.js';

export default class Search extends CoreComponent {
  render() {
    this.el.classList.add('search');
    this.el.innerHTML = /* html */ `
      <input value="${movieStore.state.searchText}"placeholder="Enter the keywords to search" />
      <button class="btn btn-primary">Search</button>

    `
    const inputEl = this.el.querySelector('input');
    inputEl.addEventListener('input', event => {
        movieStore.state.searchText = inputEl.value;
    });

    inputEl.addEventListener('keydown', event => {
      if (event.key === 'Enter' && movieStore.state.searchText.trim()) {
        searchMovies(1);
      }
    });

    const btnEl = this.el.querySelector('.btn');
    btnEl.addEventListener('click', event => {
      if (movieStore.state.searchText.trim()) {
        searchMovies(1);
      }
    });
  }
}