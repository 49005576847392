import { CoreComponent } from '../core/core.js'
import Headline from '../components/Headline'
import Search from '../components/Search.js';
import MovieList from '../components/MovieList.js';
import MovieListMore from '../components/MovieListMore.js';

export default class Home extends CoreComponent {
  render() {
    const headline = new Headline().el;
    const search = new Search().el;
    const movieList = new MovieList().el;
    const movieListMore = new MovieListMore().el;
    this.el.classList.add('container', 'mx-auto', 'px-4', 'py-4');
    this.el.append(
      headline, search, movieList, movieListMore
    )
  }
}