import { CoreComponent } from "../core/core";
import aboutStore from '../store/about.js'

export default class Footer extends CoreComponent {
  constructor() {
    super({
      tagName: 'footer',
    })
  }
  render() {
    const { name, web } = aboutStore.state;
    // alert(name)
    this.el.classList.add('w-full');
    this.el.innerHTML =  /* html */ `
      <div class="container mx-auto p-4">
       <p>${name}</p>
        <a href="${web}">Web</a>
        <div class="mt-4 text-gray-400 text-xs">
          <span>ⓒ siimplelab </span><span>${new Date().getFullYear()}</span>
        </div>
        
      </div>
    `
  }
}