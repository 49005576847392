// Default keyword가 없으면 이름을 가지는 내보내기 방식
export class CoreComponent {
  // 객체 구조로 인자를 받을 수 있도록 함
  // 'constructor': A special method for class
  // constructor: A special method for creating and initializing objects created by a class. In a class, there can be only one constructor method.
  // static methods: These are methods that belong to the class itself, not to any particular object instance of that class. You call them on the class itself rather than an instance of the class.
  // getters and setters: Special methods to define custom logic when accessing and setting properties on objects. They are prefixed with get and set, respectively.
  // prototype methods: Any method you define on a class, which is not static, is a prototype method. These methods are available on all instances of the class.
  constructor(payload = {}) {
    // 객체 구조 분해 할당(객체의 속성 꺼내기 및 기본값 할당)
    // 하기 내용은 payload 객체의 속성을 꺼내어 사용하겠다는 의미
    // payload 객체가 없을 경우 기본값을 사용하겠다는 의미
    const {
      tagName = 'div',
      state = {},
      props = {}
    } = payload;
    this.test = document.createElement('div');
    this.el = document.createElement(tagName);
    this.state = state;
    this.props = props;
    // 인스턴스 생성 시 render 메소드를 최초 실행
    this.render();
  }
  // 'render': A custom method
  render() {
    console.log('This default and it is overrided by child class.');
  }
}

// Router Function
function routeRender(routes) {
  // alert("routes: " + JSON.stringify(routes[0].component));
  // console.dir("routes: "+JSON.stringify(routes[0].component));
  // const jsonTest = JSON.stringify(class Home extends (0, _coreJs.CoreComponent) {
  //   render() {
  //     const headline = new (0, _headlineDefault.default)().el;
  //     const search = new (0, _searchJsDefault.default)().el;
  //     const movieList = new (0, _movieListJsDefault.default)().el;
  //     const movieListMore = new (0, _movieListMoreJsDefault.default)().el;
  //     this.el.classList.add("container");
  //     this.el.append(headline, search, movieList, movieListMore);
  //   }
  // });
  // alert("routes: "+JSON.stringify(routes));
  // alert("location: "+location)
  // const checker = document.createElement('div');
  // checker.textContent =
  //   'Checker: ' +
  //   'location.hash: ' +
  //   location.hash;
  // document.body.appendChild(checker);


  if (!location.hash) {
    // alert('No hash')
    // history.replaceState(null, '', '#/');
    history.replaceState(null, '', '/#/404');
    // replaceState() 메서드는 브라우저의 세션 히스토리 스택에 상태를 추가하지 않고, 현재 상태를 변경합니다.
  }

  const routerView = document.querySelector('router-view');
  // http://.../#/about
  // #/about?name=abc (해쉬 라우터)
  const [hash, queryString = ''] = location.hash.split('?');
  // console.log(location.hash);

  // 1. history에 상태값 저장 
  // a=1234&b=5678
  // 위의 Query String을 { a: 1234, b: 5678 }과 같은 객체로 변환
  const query = queryString.split('&').reduce((acc, cur) => {
    const [key, value] = cur.split('=');
    acc[key] = value;
    return acc;
  }, {})
  // 콘솔 창에서 history 객체의 상태를 확인하면 상태가 추가된 것을 확인할 수 있음(history 입력 후 엔터)
  // alert('query: ' + JSON.stringify(query))
  history.replaceState(query, '')
  // history.state만 변경하고 실제로 URL을 변경하거자 하진 않음
  // a는 a 값이고 b는 b 값이 됨
  // id가 있을 경우 id값을 가져옴

  // 2. 라우팅
  // find() 메소드는 주어진 판별 함수를 만족하는 첫 번째 요소의 값을 반환합니다. 그런 요소가 없다면 undefined를 반환합니다.
  // alert('hash: ' + hash)
  const currentRoute = routes.find(route => {
    return new RegExp(`^${route.path}(?![^#])`).test(hash);
  })

  if (routerView) {
    routerView.innerHTML = '';
    // alert("currentRoute: " + currentRoute)
    if (currentRoute && currentRoute.component) {
      routerView.append(new currentRoute.component().el);
    } else {
      // history.replaceState(null, '', '#/404')
      // routerView.append('hi');
    }
  }
  // 페이지의 스크롤 위치 초기화(최상단 배치)
  window.scrollTo(0, 0);
}

export function createRouter(routes) {
  return function () {
    window.addEventListener('popstate', () => {
      routeRender(routes)
    })
    // popstate: 브라우저의 세션 히스토리가 변경될 때 발생
    routeRender(routes)
  }
}

// * Store
export class Store {
  constructor(state) {
    console.log("state: " + state)
    console.log("this.state: " + this.state)
    // alert(this.state)
    this.state = {}
    this.observers = {}
    for (const key in state) { // 키를 인자로 받음
      // 어떤 오브젝트의 속성 정의
      // Object.defineProperty(obj, prop, descriptor)
      // definedProperty works as a detector for the object's property
      Object.defineProperty(this.state, key, {
        get: () => { // 값을 반환할 때 실행됨
          return state[key]
        }, // 속성의 값을 반환
        // message 값이 변경되면 setter 함수가 실행됨
        set: (val) => { // 값을 인자로 받음, 값이 변경되면 실행됨
          console.log('val: ' + val)
          state[key] = val;
          // alert(this.ob[key])
          // this.observers[key]();
          if (!this.observers[key]) {
            this.observers[key] = []; // 배열로 초기화
          }
          console.log("this.observers[key]: " + this.observers[key])
          this.observers[key].forEach(observer => {
            console.log("observer: " + observer);
            return observer(val)
          })
        } // 속성의 값을 설정
      })
    }
  }
  // 내용을 지속 추가할 수 있도록 옵저버 정의
  subscribe(key, cb) { // 구독할 데이터의 이름과 콜백 함수를 인자로 받음
    // 여러 함수를 적용할 수 있도록 함
    // {[cb1, cb2, cb3, ...]}
    Array.isArray(this.observers[key])
      ? this.observers[key].push(cb)
      : this.observers[key] = [cb]
    // observers[key]가 배열이면 cb를 push
    // observers[key]가 배열이 아니면 배열로 만들고 cb를 push
    // observers[key]에 cb를 할당
    // this.observers[key] = cb
  }
}