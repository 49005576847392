import { CoreComponent } from "../core/core";
import aboutStore from '../store/about.js'

export default class About extends CoreComponent {
  render() {
    const { name, web, email, photo} = aboutStore.state;
    this.el.classList.add('container', 'about')
    this.el.innerHTML = /* html */ `
    <img src="${photo}" alt="${name}">
      <h1>About</h1>
      <p>
        This is a SPA (Single Page Application) project.
      </p>
    `;
  }
}