import { CoreComponent } from "../core/core";
import movieStore, { getMovieDetails } from '../store/movie.js';

export default class Movie extends CoreComponent {
  async render() {
    // 초기 SKELETON
    this.el.classList.add('container', 'the-movie');
    this.el.innerHTML = /* html */ `
      <div class="poster skeleton">a</div>
      <div class="specs">
        <div class="title skeleton">b</div>
        <div class="labels skeleton">c</div>
        <div class="plot skeleton">d</div>
      </div>
    `

    // history.state.id는 내장객체
    await getMovieDetails(history.state.id);
    // 비동기로 선언한 함수이기에 await 키워드 붙임
    // 히스토리에서 ID 값 가져오기
    console.log('movieStore.state.movie: ', movieStore.state.movie);
    const { movie } = movieStore.state;
    const bigPoster = movie.Poster.replace('SX300', 'SX500');
    // replace 메서드로 이미지 사이즈 변경
    // What does replace() do in JavaScript?
    // The replace() method searches a string for a specified value, or a regular expression, and returns a new string where the specified values are replaced.

    this.el.classList.add('container', 'the-movie');
    this.el.innerHTML = /* html */ `
      <div style="background-image: url(${bigPoster});" class="movie-image"></div>
      <div>
      ${movie.Title}</div>
      <div class="labels">
        <span>${movie.Year}</span>
        &nbsp;/&nbsp;
        <span>${movie.Runtime}</span>
        &nbsp;/&nbsp;
        <span>${movie.Country}</span>
      </div>
      <div class="plot">
      ${movie.Plot.full}</div>
      <div>
        <h3>Rating</h3>
        ${movie.Ratings.map(rating => {
      return `<p>${rating.Source} - ${rating.Value}</p>`
    }).join('')}
      </div>
      <div>
        <h3>Actor</h3>
        <p>${movie.Actor}</p>
      </div>
      <div>
        <h3>Director</h3>
        <p>${movie.Director}</p>
      </div>
      <div>
        <h3>Writer</h3>
        <p>${movie.Writer}</p>
      </div>

      <div>
        <h3>Production</h3>
        <p>${movie.Production}</p>
      </div>

    `
  }
}