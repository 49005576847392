import {CoreComponent} from '../core/core.js'

export default class NotFound extends CoreComponent {
  render() {
    // alert('Not Found Page'); 
    this.el.classList.add('container', 'not-found')
    this.el.innerHTML = /* html */ `
      <h1>404</h1>
    `
  }
}
