import { Store } from '../core/core.js'

const store = new Store({
  searchText: '',
  page: 1,
  pageMax: 1,
  movies: [],
  movie: {}, // 빈 객체로 초기화
  loading: false,
  message: 'Search for the movie title'
})

export default store;
export const searchMovies = async page => {
  // 써치 실행 시 바로 로딩 표현
  store.state.loading = true
  store.state.page = page;
  // page가 1일 때는 영화 배열 초기화
  if (page === 1) {
    // store.state.page = 1;
    store.state.movies = [];
    store.state.message = ''
  }

  // store.state.movies = [
  //   ...store.state.movies,
  //   ...Search
  // ]
  // console.log('Search: ', Search);
  // console.log(json);

  try {
    // const res = await fetch(`https://omdbapi.com/?apikey=key&s=${store.state.searchText}&page=${page}`)
    const res = await fetch('/api/movie', {
      method: 'POST',
      body: JSON.stringify({
        title: store.state.searchText,
        page
      })
    });
    // fetch는 비동기 처리
    // const json = res.json(); // 서버에서 받은 정보 분석
    // API가 Search, totalResults, Response 등의 키를 가지고 있음
    const { Search, totalResults, Response, Error } = await res.json(); // 비동기 처리
    // let resJson = await.res.json();
    // let Search = resJson.Search();

    if (Response === 'True') {
      store.state.movies = [
        ...store.state.movies,
        ...Search
      ]
      store.state.pageMax = Math.ceil(Number(totalResults) / 10);
    } else {
      store.state.message = Error;
    }
  } catch (error) {
    // 상태 초기화 코드
    store.state.pageMax = 1;
    // 에러 표출 코드
    console.log('searchMovies error: ', error);
  } finally {
    store.state.loading = false;
    // 모든 처리 후 로딩 false로 변경
  }

  console.log("store.state.movies: " + store.state.movies);
}

export const getMovieDetails = async id => {
  try {
    // const res = await fetch(`https://omdbapi.com/?apikey=key&i=${id}`)
    const res = await fetch('/api/movie', {
      method: 'POST',
      body: JSON.stringify({

        id

      }),
    })
    store.state.movie = await res.json();

  } catch (error) {
    console.log('getMovieDetails error: ', error);
  }
}