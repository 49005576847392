import { CoreComponent } from "../core/core"; 

export default class Headline extends CoreComponent {
  render() {
    this.el.classList.add('headline');
    this.el.innerHTML = /* html */ `
      <h1>
        OMDB API
      </h1>
      <p>
        This is a headline component.
      </p>
    `
  }
}