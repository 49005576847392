import { CoreComponent } from '../core/core.js';

export class Header extends CoreComponent {
  constructor() {
    super({
      tagName: 'header',
      state: { // The namce can be anything not only state but also data, props, etc. 
        menus: [
          {
            name: 'Search',
            href: '#/'
          },
          {
            name: 'Random',
            href: '#/movie?id=tt4520988'
          },
          {
            name: '﹖',
            href: '#/about'
          }
        ]
      }
    });
    window.addEventListener('popstate', () => {
      this.render();
    })
  }
  render() {
    this.el.classList.add('header');
    this.el.innerHTML = /* html  */ `
      <div class="container flex     mx-auto px-4 py-2 
">
      <a href="#/" class="mr-auto">🍿</a>
      <nav>
        <ul>
        ${this.state.menus.map(menu => {
          const href = menu.href.split('?')[0];
          const hash = location.hash.split('?')[0];
          const isActive = href === hash 
          return /* html */ `
            <li>
              <a 
                class="${isActive ? 'active' : ''}"
                href="${menu.href}">${menu.name}</a>
            </li>
          `
        }).join('')}
        </ul>
      </nav> 
      <a href="#/user" class="ml-auto nav-user">User</a> 
      </div>
    `
  }
}