import './main.scss';
import App from './App.js';
import router from './routes';

const root = document.querySelector('#root');
root.append(new App().el);

router();

// SERVERLESS FUNCTION
// 즉시 실행 함수
// 'api/test'는 vercel 제공 API로 보임
;(async () => {
  const res = await fetch('/api/test');
  const json = await res.json();
  console.log('api/test :', json)
})();