import { CoreComponent } from "../core/core";

export default class MovieItem extends CoreComponent {
  constructor(props) {
    // 부모가 자식에게 값 넘겨줄 때의 props
    super({
      props,
      tagName: 'a'
    })

  }
  render() {
    const { movie } = this.props;
    // 영화 정보 하나를 movie에 할당
    this.el.setAttribute('href', `#/movie?id=${movie.imdbID}`);
    this.el.classList.add('movie');
    this.el.style.backgroundImage = `url(${movie.Poster})`;
    this.el.innerHTML = /* html */ `
      <div class="info">
        <strong>${movie.Title}</strong>
        <span>${movie.Year}</span>
      </div>
    `
  }
}