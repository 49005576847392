import { createRouter } from '../core/core'
import Home from './Home'
import Movie from './Movie'
import About from './About'
import NotFound from './NotFound'

export default createRouter(
  [
    { path: '#/', test: 'test', component: Home },
    { path: '#/movie', component: Movie },
    { path: '#/about', component: About }, // it would be {} when you do JSON.stringify(routes) in console
    // { path: '#/404', component: NotFound }
    // { path: '.{0,}', component: NotFound } 
    { path: '.*', component: NotFound } 
    // This regular expression matches any character (.) zero or more times ({0,}), which means it will match any path.
    // The astrisk (*) is a quantifier that matches the preceding element zero or more times.
  ]
)